<template>
    <div class="page">
        <div class="background-box">
            <h4>尊敬的合作客户：</h4>
            <p> &nbsp;&nbsp;&nbsp;&nbsp;
                我们致力于保护您在使用我们网站时所提供的隐私、私人资料、以及个人资料（统称“个人资料”），在我们收集、使用、存储和传送个人资料方面符合
                （与个人资料隐私有关的法律法规）及消费者保护方面的最高标准。为确保您对我们在处理个人资料上有充分信心，您切要详细阅读及理解隐私政策的条文。
            </p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;
                特别是您一旦使用我们的网站,将被视为接受、同意、承诺和确认；您在自愿下连同所需的同意向我们披露个人资料；您会遵守本隐私政策的
                全部条款和限制；您在我们的网站上做登记、资料会被收集；您同意日后我们对隐私政策的任何修改；您同意我们的分公司、附属公司、雇员
                、就您可能会感兴趣的产品和服务与您联络（除非您已经表示不想收到该信息）。被收集的个人资料的种类经您的同意，我们会收集、管理和
                监控个人资料。
            </p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;
                为了向您提供我们的各项服务，您需要提供个人资料信息，其中包括个人资料和不具名的资料，包括但不限于：个人资料（您的姓名、性别、
                年龄、出生日期、电话号码、传真号码、住址或通讯地址、电子邮箱地址）。
            </p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;
                收集个人资料及不具名的资料的目的及用途如下：通过我们的网站向您提供我们的各项服务；当您使用我们的网站时，能辨认以及确认您的身份；
                让您使用我们的网站时得到为您而设的服务；我们的顾客服务人员有需要时可以与您联系；统计我们网站使用量的数据；让您在使用我们网站时更方
                便；为改进我们的产品、服务及网站内容而进行市场研究调查；为我们搞的活动、市场销售和推广计划收集资料；遵守法律、政府和监管机关的规定，
                包括但不限于对个人资料披露及通知的规定；就我们提供的各项服务、核对或审查您的信用、付款或地位；处理在您要求下的任何付款指示，直
                接扣帐或信用安排；使您能运作您的账户以及使我们能从账户支取尚欠的服务费；您提供给我们的个人资料及不具名资料，只保留到搜集的目的已达到
                的时候，除非应适用的法律法规之规定而继续保留。个人资料的拥有权及披露在我们网站上所搜集的一切资料都由我们所拥有，不会出租或出售给任
                何无关的第三方。
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                您有权：查询我们是否持有您的任何个人资料；接达我们所持有的您的个人资料；要求我们更正任何不正确的个人资料；不时地征询有关我们所
                持有的个人资料的性质，政策和执行方法；然而在法律允许的极端有限的情况下，我们可以不允许您接达您的个人资料，例如：如您接达及得到您个人
                资料可能会对您有危险；当您的个人资料可能会影响一项正在进行的调查；当您的个人资料涉及到法庭程序，并且可能受到发现的限制；当您的个人资
                料涉及一项商业上敏感的决策过程；当另外一個人的个人资料也包含在同一份记录中；若您欲接达或更正个人资料，或索取有关个人资料的政策、执行
                方法和被持有的个人资料的种类，应致函到我们的下列的地址；要求接达或更正资料可能要付合理的处理费用；安全保管您的密码，除了我们致力确保
                您的个人资料存放和处理的安全外，您不应向任何人披露您的登录密码或帐户资料，以保护您的个人资料。

            </p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                每当您登录我们网站时，尤其是当您使用他人的电脑或者是公共的互联网终端机时，请记着操作完毕后一定要点击退出。
            </p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                您的努力和协助对于我们保护您的个人资料绝对有帮助。
            </p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                隐私政策的修改：
                本隐私政策可以不时无需事先向您通知被修改。任何对隐私政策的修改都会刊登在我们网站上。
            </p>
            <h4 style='text-align: right;'>山东通信科技有限公司</h4><h4 style='text-align: right;'>
            签发日期：2021年04月27日</h4>
            <h4 style='border-bottom: 1px solid #A9A9A9;margin-bottom: 50px'></h4>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                params: {},

            }
        },
        mounted() {

        },
        methods: {},
        computed: {}
    }

</script>

<style lang="less" scoped>
    .background-box {
        min-height: calc(100vh - 0px);
        background: #FFFFFF;
        padding: 10px;
    }

</style>
